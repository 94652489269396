<template>
  <div>

    <b-card 
      no-body 
      class="small-card-service cursor-pointer" 
      @click="$emit('click', card)"
    >
      <router-link 
        :to="redirectsTo=='view' ? `/integrator/service/${card.identifier}/view` : (redirectsTo=='edit' ? `/integrator/service/${card.id}/edit` : '') "
      >
        <b-row class="p-2">
          <b-col cols="3" class="small-card-service-avatar px-0 mx-1">
            <b-avatar size="95px" class="" rounded src="https://placekitten.com/1000/300">
              <template v-if="!card.image_uri">
                <div class="position-absolute bg-light-purple rounded-circle small-card-service-avatar-background">
                  </div>
                <span class="text-purple" style="font-size: 36px">
                  {{ getAvatarString }}
                </span>
              </template>
            </b-avatar>
          </b-col>
          <b-col class="ml-0 mr-1 p-0 small-card-service-body">
            
            
            <b-button @click.prevent="''" variant="none" class="p-25 m-0 d-inline float-right" :id="'btn-fav'+card.id" :disabled="loading_favorite_toggle">
              <b-spinner v-if="loading_favorite_toggle" variant="purple" class="m-0 p-0" small/>
              <b-icon
                v-else
                variant="purple"
                :icon="card.is_favorite ? 'bookmark-fill' : 'bookmark'"
                scale="1.25"
              />
            </b-button>

            <b-popover :target="'btn-fav'+card.id" :key="'popover-fav'+card.id+card.is_favorite" :ref="'popover-fav'+card.id" triggers="focus" variant="purple" placement="top" custom-class="custom-popover-body-servicecard">
              <template #default>
                <small>
                <div align="center">
                  <span v-if="card.is_favorite"><b>{{$t('integrator.tabs.general.small_card.favorite_popover.unfavorite')}}</b></span>  
                  <span v-else><b>{{$t('integrator.tabs.general.small_card.favorite_popover.favorite')}}</b></span>
                  <br>
                  <b-button class="px-1 py-25 mt-25" variant="purple" @click="toggleFavorite(card.is_favorite)">{{$t('integrator.tabs.general.small_card.favorite_popover.confirm')}}</b-button>
                </div></small>
              </template>
            </b-popover>

            
            <p class="text-light mb-0 small-card-service-title">
              {{ card.name }}
            </p>
            
            <div class="mt-25">
              <router-link :to="`/integrator/organization/${card.organization.id}/services`" :title="'Go to Organization '+card.organization.name ">
                <span class="text-secondary small-card-service-organization m-0 p-0">
                  <b>{{card.organization.name}}</b>
                </span>
              </router-link>
            </div>

            <p class="text-secondary mb-0 small-card-service-rate">
              {{ card.average_rating.toFixed(1) }}
              <b-icon
                icon="star-fill"
                scale="0.8"
              />
            </p>
          </b-col>
        </b-row>
      </router-link>
    </b-card>
  </div>
</template>

<script>
import { 
  BCard,
  BCardBody,
  BRow,
  BCol,
  BAvatar,
  BLink,
  BButton,
  BModal,
  BPopover,
  BSpinner,
} from 'bootstrap-vue'
  export default {
    components: {
      BCard,
      BCardBody,
      BRow,
      BCol,
      BAvatar,
      BLink,
      BButton,
      BModal,
      BPopover,
      BSpinner,
    },
    props: {
      value: {
        type: Object,
        required: true,
      },
      redirectsTo:{
        type: String,
        required: true,
      }
    },
    data() {
      return {
        loading_favorite_toggle: false,
      }
    },
    computed: {
      card: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      getAvatarString() {
        let arr = this.card.name.split(/[_,-\s]/)
        if (arr.length < 2) {
          return arr[0].substring(0, 2).toUpperCase()
        } else {
          return `${arr[0][0]}${arr[1][0]}`.toUpperCase()
        }
      },
      organizationID(){
        return localStorage.getItem('selectedOrganization');
      }
    },
    methods: {
      toggleFavorite(is_already_favorite){
        //close popover
        this.$refs['popover-fav'+this.card.id].$emit('close')

        this.loading_favorite_toggle = true
        let operation;
        if (is_already_favorite){
          operation = 'remove'
        } else {
          operation = 'put'
        }

        this.$store.dispatch('serviceModule/toggleFavorite', {organizationID: this.organizationID, integrator_service:this.card.id, operation: operation})
          .then(() => {
            // this.card.is_favorite = !is_already_favorite;
            this.$set(this.card, "is_favorite", !is_already_favorite);
          }).catch((error) => { 
            console.error(error)
          }).finally(() => {
            this.loading_favorite_toggle = false
            
          })
      },
    },
  }
</script>

<style lang="scss" scoped>

.small-card-service {
  background-color: #1f263b !important;
  box-shadow: none !important;
  transition: all 0.3s ease !important;

  &:hover {
    background-color: #283046 !important;
    transition: all 0.3s ease !important;
  }
  .small-card-service-avatar {
    .small-card-service-avatar-background {
      padding: 35px 35px !important;
    }
  }
  .small-card-service-body {
    .small-card-service-title {
      margin-top: -5px !important;
      font-weight: 500 !important;
      font-size: 21px !important;
      line-height: 26px !important;
    }
    .small-card-service-organization {
      margin-top: 10px !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 17px !important;
      letter-spacing: 0.4px !important;
      color: #B4B7BD !important;
      :hover{
        text-decoration: underline;
      }
    }
    .small-card-service-rate {
      margin-top: 30px !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 17px !important;
      letter-spacing: 0.4px !important;
      color: #B4B7BD !important;
    }
  }
}
</style>

<style lang="scss">
.custom-popover-body-servicecard{
  .popover-body{
    border-radius: 15px !important;
  }
  background: none !important;
}
</style>