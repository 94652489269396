<template>
  <div>
    <div class="d-flex">
      <div class="mx-50 w-50">
        <h5 class="">{{ $t("integrator.tabs.library.content.searching.categories") }}</h5>
        <service-categories-multiple @catSelected="categorySelected(...arguments)" class="ml-0" :initicalCatId="searchParams.cat" v-if="Array.isArray(searchParams.cat)"/>
      </div>
      <div class="mx-50 w-50" align="right">
        <h5 class="mb-75">{{ $t("integrator.tabs.library.content.searching.sort_by") }}</h5>
          <small>
            <div class="d-flex justify-content-end">

              <b-button class="py-0 m-0 px-25" variant="none" @click="toggleDir()">
                <b-icon icon="chevron-double-down" scale="1"  class="transitional m-0 p-0" :class="searchParams.dir=='ASC' ? 'rotate180' : ''"/>
              </b-button>

              <v-select :options="sortOptions" v-model="searchParams.sort" class="w-25" :clearable="false" @input="updateSort()">
                <template #option="option">
                  <span>{{option.label}}</span>
                </template>
              </v-select>
            </div>
          </small>
      </div>
    </div>

    <b-container fluid class="mt-1 p-0" > <!-- SERVICE CONTAINER -->

          <div v-if="Array.isArray(shownServices)">
            <b-row cols="12"> 
              <b-col md="4" v-for="(item, idx) in shownServices" class="mt-2 px-1" :key="'search-service-card-'+idx">
                <router-link :to="{ name: 'integrator-service-view', params: { serviceIdentifier: item.identifier }}" class="text-reset">
                  <b-row no-gutters class="service-card py-1 animate__animated animate__fadeIn animate__faster" >
                    <b-col md="9" class="d-flex">
                      <div class="avatar-container">
                        <b-avatar size="75" :src="item.cover_uri"/>
                      </div>
                      
                      <div class="mx-1 d-flex align-items-between w-100">
                        <div>
                          <h4 class="mb-0">{{item.name}}</h4>  
                          
                          <router-link :to="{ name: 'integrator-org-service-view', params: { orgID: item.organization.id }}" class="text-reset">
                            <span class="text-secondary m-0 small org-link"><b>{{item.organization.name}}</b></span>
                          </router-link>
                      
                          <p class="text-secondary small m-0 pt-75">
                            <small class="mr-1"><b-badge variant="light-purple" class="" pill>{{item.integrator_category.label}}</b-badge></small>
                            <b-form-rating class="m-0 p-0 custom-form-rating" inline :value="item.average_rating" readonly no-border precision="3" color="rgba(207, 157, 41, 0.75)" size="sm"/>
                          </p>
                        </div>
                      </div>                

                    </b-col>
                    <b-col md="3" align="right" class="d-flex flex-wrap">

                      <div class="d-flex justify-content-end w-100">
                        <div class="mr-2" :class="item.is_installed ? '' : 'invisible'" :title="$t('integrator.tabs.library.content.searching.search_card.installed_info')">
                          <b-iconstack shift-v="-3" scale="0.85">
                            <b-icon icon="download" scale="1.5"/>
                            <b-icon icon="check" variant="success" scale="1.2" shift-h="11" shift-v="8"/>
                          </b-iconstack>
                        </div>  
                        
                        <div>
                          <b-button variant="none" class="p-0" @click="toggleServiceFavorite()" @click.prevent>
                            <b-icon v-if="item.is_favorite" icon="bookmark-fill" scale="1.1" variant="purple"/>
                            <b-icon v-else icon="bookmark" scale="1.1" variant="purple"/>
                          </b-button>
                        </div>
                      </div>
                      
                      <div class="w-100 align-self-end">
                        <p class="small text-muted m-0 line-height-smaller"><b>{{item.downloads_counter}}</b> {{ $t("integrator.tabs.library.content.searching.search_card.installations") }} </p>
                      </div>
                    
                    </b-col>
                  </b-row>
                </router-link>
              </b-col>
            </b-row>

            <div v-if="shownServices.length == 0">
              <h4 class="text-center text-secondary">{{ $t("integrator.tabs.library.content.searching.not_found") }}</h4>
            </div>

            <div class="d-flex justify-content-center mt-2" v-if="searchParams.idx">
              <!-- <b-pagination variant="purple" v-model="searchParams.idx" @input="searchServices()" :per-page="2" :total-rows="20"></b-pagination> -->
              <b-button class="py-25 px-50 round" variant="outline-purple" @click="shiftPage(-1)" :disabled="searchParams.idx == 1">
                <b-icon icon="caret-left-fill" scale="0.8" variant=""/>
              </b-button>
              <b-avatar size="30" class="mx-50 bg-purple text-black">
                {{searchParams.idx}}
              </b-avatar>
              <b-button class="py-25 px-50 round" variant="outline-purple" @click="shiftPage(1)" :disabled="!((searchParams.idx*24) < totalServicesMatched)">
                <b-icon icon="caret-right-fill" variant=""/>
              </b-button>
            </div>
            
          </div>
          <div v-else>
            <b-row class=""> 
              <b-col class="px-1" v-for="i in 12" :key="'bskeleton-service-'+i" md="4">
                <b-skeleton width="100%" height="100px" class="mt-1"/>
              </b-col>
            </b-row>
          </div>
    </b-container>
  </div>
</template>

<script>
  import { 
    BRow,
    BCol,
    BFormInput,
    BButton,
    BCard,
    BFormGroup,
    BInputGroup,
    BSkeleton,
    BContainer,
    BAvatar,
    BBadge,
    BFormRating,
    BPagination,
    BCollapse
  } from 'bootstrap-vue';
  
  import ServiceCategoriesMultiple from '@/views/pages/integrator/components/MyServices/ServiceCategoriesMultiple.vue';
  import VSelect from 'vue-select';

export default {
  components: {
    ServiceCategoriesMultiple,
    VSelect,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BCard,
    BFormGroup,
    BInputGroup,
    BSkeleton,
    BContainer,
    BAvatar,
    BBadge,
    BFormRating,
    BPagination,
    BCollapse
  },
  data() {
    return {
      searchTimer: undefined,
      sortOptions: [
        "Service Name",
        "Last Updated",
        "Downloads",
        "Rating"
      ],
      sortConverter: {
        "Service Name": 'name',
        "Last Updated": 'updated_at',
        "Downloads": 'downloads_counter',
        "Rating": 'average_rating'
      },
      searchParams: {
        srch: undefined,
        sort:undefined,
        idx: undefined,
        cat: undefined,
        dir: undefined
      },
      defaultValues: {
        srch: '',
        sort:'Service Name',
        idx: 1,
        cat: ['0'],
        dir: "ASC"
      },
      shownServices: undefined,
      totalServicesMatched: undefined
    }
  },
  props: {
		value: {
			type: String,
			required: true,
		},
  },
  computed: {
    searchTerm: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			}
		},  
    organizationID(){
      return localStorage.getItem('selectedOrganization')
    }
  },
  mounted () {
    this.init();
  
  },
  watch: {
    searchTerm(newTerm, oldTerm) {
      if ((newTerm.length > 200) || (newTerm == oldTerm)){
        return;
      }
      this.shownServices = undefined
      this.$set(this.searchParams, 'srch', newTerm)
      
      this.addParamsToLocation()
      clearTimeout(this.searchTimer)

      this.searchTimer = setTimeout(()=>{
        this.searchServices()
      }, 1000);  
    },
  },
  methods: {
    init(){  
      this.getParamsFromUrl()
      this.$nextTick(()=>{
        this.searchServices()
      })
      
    },
    categorySelected(catId){
      if (!Array.isArray(catId)){
        return
      }

      this.$set(this.searchParams, 'cat', catId);
      this.addParamsToLocation();
      this.searchServices()
    },  
    updateSort(){
      this.addParamsToLocation();
      this.searchServices()
    },
    searchServices(){
      this.shownServices = undefined
    
      let url = new URL(window.location.href)
      
      //order opts: name , downloads_counter , average_rating , updated_at , 
      //ASC or DESC
      
//      order : 'created_at DESC'+',name ASC',
      let treatedSort = this.sortConverter[this.searchParams.sort]

      let queries = {
        search_name: this.searchTerm || '',
        org: this.organizationID, //FIXED
        order : `${treatedSort} ${this.searchParams.dir} ,name ASC`,
        page: this.searchParams.idx,
        per_page: 24, //FIXED (divisible by either 2, 3 or 4 - possible number of columns)
        categories: this.treatCatToQuery(this.searchParams.cat)
      }

      this.$store.dispatch('serviceModule/searchIntegratorServices', {queries: queries})
        .then((resp)=>{
          this.shownServices = resp.items
          this.totalServicesMatched = resp.paginator.total_items
        })
        .catch((err)=>{
          console.error(err)
        })
    },
    addParamsToLocation() {
      let params = structuredClone(this.searchParams);
      // params.cat = JSON.stringify(params.cat)
      // params.cat = params.cat.substring(1, params.cat.length-1)
      params.cat = this.treatCatToQuery(params.cat)

      history.pushState(
        {},
        null,
        this.$route.path +
          '?' +
          Object.keys(params)
            .map(key => {
              return (
                encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
              )
            })
            .join('&')
      )
    },
    clearParams(){
      clearTimeout(this.searchTimer);
      history.pushState({}, null, this.$route.path); //this clears Url Parameters
    },
    getParamsFromUrl(){
      let url = new URL(window.location.href)
      let initSrch = url.searchParams.get('srch')
      let initIdx = url.searchParams.get('idx')
      let initCat = url.searchParams.get('cat')
      let initSort = url.searchParams.get('sort')
      let initDir = url.searchParams.get('dir')

      initCat = initCat?.split(',')

      switch (true){
        case !initSrch:
          initSrch = structuredClone(this.defaultValues.srch)
        case !initIdx:
          initIdx = structuredClone(this.defaultValues.idx)
        case !initCat:
          initCat = structuredClone(this.defaultValues.cat)
        case !initSort:
          initSort = structuredClone(this.defaultValues.sort)
        case !initDir:
          initDir = structuredClone(this.defaultValues.dir)
      }
      
      this.searchParams.srch = initSrch
      this.searchParams.idx = initIdx
      this.searchParams.cat = initCat
      this.searchParams.sort = initSort
      this.searchParams.dir = initDir
      this.searchTerm = initSrch
    },
    treatCatToQuery(cats){
      let temp = ''
      cats.forEach(el => {
        temp = temp + el + ','
      });
      temp = temp.slice(0,-1)
      return temp
    },
    shiftPage(n){

      let r = parseInt(this.searchParams.idx)
      r = r + n
      if (r<1){
        return
      }
      this.searchParams.idx = String(r)

      this.addParamsToLocation()
      this.searchServices()
    },
    toggleServiceFavorite(){

    },
    toggleDir(){
      switch (this.searchParams.dir){
        case 'ASC':
          this.searchParams.dir = 'DESC'
          break
        case 'DESC':
          this.searchParams.dir = 'ASC'
          break
        default:
          this.searchParams.dir = 'ASC'
      }
      this.addParamsToLocation()
      this.searchServices()
    }
  },
}
</script>

<style lang="scss" scoped>

.list-enter-active{
  transition: all 1s ease !important
}
.list-leave-active{
  opacity: 0 !important;
}
.list-enter-from, .list-leave-to {
  opacity: 0 !important;
}

  .service-card{
    background-color: #283046;
    padding: 15px;
    border-radius: 5px;    
    transition: all 0.2s;
    .avatar-container{
      background-color: var(--dark-blue);
      width: fit-content;
      padding: 5px;
      border-radius: 5px;
    }
    .org-link:hover{
      text-decoration: underline;
    }
  }

  .service-card:hover{
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
  }
  .service-card:active{
    outline: 1px solid black;
  }

  .line-height-smaller{
    line-height:15px;
    margin-bottom: 6px !important;
  }
</style>

<style lang="scss">
  .custom-form-rating{ 
    .b-rating-star{
      padding: 0 3px 0 0;
    }
  }
  .transitional{
    transition: all 0.3s;
  }
  .rotate180{
    transform: rotate(180deg);
  }
</style>
